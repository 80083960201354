.flex_row_center_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1300px) {
  .nav_logo {
    display: none;
  }
}
@media (max-width: 990px) {
  .nav_logo {
    display: block;
  }
}
@media (max-width: 767px) {
  .header_logo {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1044px) {
  .nav_txt {
    font-size: 18px;
  }
}

@media (max-width: 448px) {
  .about_txt {
    line-height: 0 !important;
  }
}
@media (max-width: 1200px) {
  .remove-below-1200 {
    display: none;
  }
}
@media (max-width: 768px) {
  .remove-left-right-100 {
    padding: 0px !important;
  }
}

@media (max-width: 430px) {
  .line-height-0-all {
    line-height: 0;
  }
}

@media (max-width: 1199px) {
  .left-testimonial {
    /* padding-left: 0 !important; */
    width: 50%;
    aspect-ratio: 1;
  }
}

@media (max-width: 700px) {
  .left-testimonial {
    /* padding-left: 0 !important; */
    width: 80%;
  }
}

@media (max-width: 500px) {
  .left-testimonial {
    padding-left: 0 !important;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .testimonial-txt {
    font-size: 60px !important;
  }
}

@media (max-width: 330px) {
  .testimonial-txt {
    font-size: 40px !important;
  }
}
@media (max-width: 1300px) {
  .tools-insights-text, .faq, .why {
    font-size: 60px !important;
  }
  .why{line-height: 2.5 !important;}
}
@media (max-width: 900px) {
  .tools-insights-text, .faq, .why {
    font-size: 40px !important;
  }
}

@media (max-width: 560px) {
  .remove-flex_row_center_center {
    display: block;
  }
}

@media (max-width: 650px) {
  .faq {
    font-size: 60px !important;
  }
}

@media (max-width: 450px) {
  .faq {
    font-size: 40px !important;
  }
}

.nav-color {
  background-color: transparent; /* Initial background color */
  transition: background-color 0.3s; /* Smooth transition effect */
}

/* Define the style for your navbar when it's scrolled */
.nav-color.scrolled {
  background-color: #121212b7; /* Change to your desired background color */
}

.dropdown-menu {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 767px) {
  .removeMargin30 {
    margin-top: 0 !important;
  }
  .removePicBelow768 {
    height: 50vh;
  }
}
@media (max-width: 576px) {
  .remove-padding-50 {
    padding: 0 !important;
  }
  .remove-margin-bottom {
    margin-bottom: 0 !important;
  }
  .remove-footer-576 {
    display: none !important;
  }
  .padding-5{
    font-size: 18px !important;
  }
}

.faq-outer-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
}

.padding-5 {
  padding: 5px;
  font-size: 24px;
}

.faq-inner-div-plus {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: bold;
  cursor: pointer;
}
.faq-left-text{
  width: calc(100% - 50px) !important;
  cursor: pointer;
}
