@tailwind base;
@tailwind components;
@tailwind utilities;
body {

  color: white;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Header CSS */


.overflow_x_hidden {
  overflow-x: hidden !important;
}


.nav_links::after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -30%;
  left: 0%;
  border-radius: 0 0 2px 2px;
  background-color: white;
  width: 0;
  transition: ease-in-out 300ms;
}

.nav_links:hover::after {
  width: 100%;
}


.showNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  opacity: 0 !important;
  min-height: 100vh;
  background-color: white;
  pointer-events: none;
  transition: all 300ms ease-in-out;
  z-index: 2;
  
}

.hideNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1 !important;
  min-height: 100vh;
  background-color: black;
  pointer-events: all;
  transition: all 300ms ease-in-out;
  z-index: 2;
}

.menu_line {
  width: 35px;
  border-radius: 4px;
  height: 3px;
  background-color: gray;
}

.menu_line_2 {
  width: 25px;
  border-radius: 4px;
  height: 3px;
  background-color: grey;
}

.btn-close {
  margin-top: 32px !important;
  font-size: 26px !important;
  color: white !important;
  /* background-color: white; */
}

.sign_btn {
  background-color: var(--clr-sky);
  padding: 18px 32px;
  border-radius: 12px;
  border: 1px solid var(--clr-sky);
}

.sign_btn:hover {
  color: white;
}
.w_logo{
  width:130px;
}
.transition_03 {
  transition: all ease-in-out 300ms !important;
}

.c_pointer {
  cursor: pointer;
}
