textarea {
  box-shadow: none !important;
  background-color: rgb(255, 252, 252) !important;
}

/* @media only screen and (min-width:1213px)
{
  #world-map-xl{
    display: none !important;
  }
  #world-map-md{
    display: none !important;
  }
} */

@media only screen and (min-width: 320px) and (max-width: 900px) {
  .banner {
    padding: 15px !important;
  }
}
/* @media only screen and (max-width:651px){
  #world-map-xxl{
    display: none !important;
  }

  #world-map-xl{
    display: none !important;
  }

} */

textarea:focus {
  border: 1px solid #34b6dd !important;
}
input[type="text"],
input[type="email"] {
  margin: 0 !important;
  border: none;
  outline: none;
  border-radius: 0%;
  border-bottom: 2px solid #aaa !important;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder {
  color: transparent;
}
.activeLabel {
  font-size: 1em;
  display: inline-flex !important;
  align-items: center;
  outline: none !important;
  color: #aaa;
  opacity: 1;
  transform: translateY(-2em);
  transform-origin: 0 0;
  transition: all 0.3s;
  margin: 0 !important;
}
input[type="text"]:focus,
input[type="text"]:active,
input[type="email"]:focus,
input[type="email"]:active {
  border-bottom: 2px solid #34b6dd !important;
  box-shadow: none;
}
input[type="text"]:not(:placeholder-shown):invalid,
input[type="email"]:not(:placeholder-shown):invalid {
  border-bottom: 2px solid red !important;
}
input[type="text"]:focus + .activeLabel,
input[type="text"]:not(:placeholder-shown) + .activeLabel,
input[type="email"]:focus + .activeLabel,
input[type="email"]:not(:placeholder-shown) + .activeLabel {
  transform: translateY(-3em) scale(0.8);
  color: black;
  font-weight: 500;
  font-size: 1.2em;
}
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.MenuButton {
  display: none;
}
.submenu {
  /* display: none; */
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.9);
  /* height: 28vh; */
  /* overflow: scroll; */
  /* background-color: rgba(0, 0, 0, 0.9);
  height: 100%; 
  width: 300px; 
  position: fixed; 
  z-index: 1; 
  top: 0; 
  right: 0;
  background-color: #111111d0; 
  overflow-x: hidden;
  padding-top: 60px; 
  transition: 0.5s; */
}
.submenu a {
  color: white;
  text-decoration: none;
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  /* color: #818181; */
  display: block;
  transition: 0.3s;
}

.career-card {
  background-color: #F5F5F5;
  border-style: solid;
  border-width: 2px;
  border-color:white;
  width: 22rem;
  margin: 10px;
  padding: 0%;
}
@media only screen and (max-width: 1117px) and (min-width: 992px) {
  .career-card {
    width: 18rem;
  }
}
@media only screen and (max-width: 991px) and (min-width: 300px) {
  .career-card {
    width: 100%;
    margin-left: 0% !important;
  }
}

.submenu a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
.topbar-links {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 30%;
}

.topbar-links a,
.topbar-links a:visited,
.topbar-links a:hover {
  color: white;
  text-decoration: none;
  justify-content: space-around;
}


.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: #34b6dd;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  color: white;
  box-shadow: none;
}
.buttonDark {
  background-color: rgb(31, 31, 31);
  display: flex;
  align-items: center;
  color: white;
  border: none;
  box-shadow: none;
}
.leftHead {
  height: 100vh;
  background-color: #131313;
}
.leftJobHead{
  height: 100vh;
  background-color: #131313;
}
.back {
  background-image: url("./assets/back.jpg");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
}

.job-right{
  background: black;
  background-image: url("./assets/MaskGroup20.png");
  background-size:98% 100%;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
}
/* .career-gradient{
  background-image: linear-gradient(to top, rgba(241, 14, 14, 0.52), rgba(117, 19, 93, 0.73));
  position: absolute;
  left: 0px;
  top: 0px;
  z-index:-1;
} */
.career-right {
  background-image: linear-gradient(to top, rgba(18, 18, 19, 0.001), black),
    url("./assets/Career.jpg");
  /* background-image: url('./assets/Career.png'); */
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
}

.layer {
  height: 100vh;
  width: 100%;
  background-color: transparent;
}
.insideLeft {
  /* padding-top: 28%;
  padding-bottom: 45%; */
  /* margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

@media  (max-width:500px) {
  .insideLeft h1{
    font-size: 2rem !important;
  }
  
  .insideLeft p{
    font-size: 1rem !important;
  }
  
}


.center-heading {
  font-weight: 500;
  font-size: 1.5em;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}
.center-heading:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 25%;
  border-bottom: 2px solid;
}
.bannerHeading {
  color: white;
  font-size: 1.5em;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
  font-weight: 500;
}
.bannerHeading:before {
  content: "";
  position: absolute;
  width: 15%;
  height: 1px;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid;
}
.loveImage-container {
  display: flex;
  justify-content: center;
}
.loveImage {
  width: 100px;
  height: 100px;
  margin-top: 25px;
}
.rec.rec-dot {
  box-shadow: none !important;
  background-color: white;
}
.rec.rec-dot_active {
  box-shadow: none !important;
  background-color: #34b6dd;
}
.rec.rec-arrow {
  border-radius: 0;
  background-color: #34b6dd !important;
  color: white;
  opacity: 0.1;
  /* transition: all 250ms; */
}
.rec.rec-arrow:hover {
  background-color: #34b6dd !important;
  color: white;
  opacity: 1;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
.testimonial {
  display: block;
  margin: 1em;
  background-color: rgb(31, 31, 31) !important;
}
@media only screen and (max-width: 900px) {
  .testimonial {
    margin: 0%;
  }
}

p {
  font-size: 1.3em;
  padding-top: 1em;
  padding-right: 1em;
}

input[type="text"] {
  box-shadow: none !important;
}
.contactForm {
  box-shadow: 0 5px 5px 5px rgba(19, 19, 19, 0.03);
  margin: -30px 10% 50px 10% !important;
}
.serviceCard {
  color: black;
  /* margin: 0em 2em; */
  /* padding: 2em; */
  box-shadow: 0px 8px 10px rgb(0 0 0 / 6%);
  border-radius: 5px;
}
.serviceCardHome {
  color: black;
  /* margin: 0em 2em; */
  /* padding: 2em; */
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
  /* clip-path: inset(0px -2px -2px -2px); */

  border-radius: 5px;
}
.prodLeft {
  height: 100vh;
  background-color: rgb(19, 19, 19);
}
.AiLeft,
.ItLeft,
.ContactLeft,
.OemLeft {
  height: 100vh;
  background-color: rgb(19, 19, 19);
}
.AiRight {
  position: relative;
  overflow: hidden;
  height: 80vh;
}
.AiRight::before {
  content: "";
  position: absolute;
  margin: 11% 0% 0% 15%;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -65%;
  z-index: 1;
  background: url("./assets/ComponentTMP_0-image6.jpg") no-repeat center;
  transform: rotate(-14deg);
}
.OemRight {
  position: relative;
  overflow: hidden;
  background-color: rgb(203, 203, 203);
  height: 80vh;
}
.OemRight::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -30%;
  left: -40%;
  z-index: 1;
  background: rgb(203, 203, 203) url("./assets/proto.png") no-repeat center;
  transform: rotate(-20deg);
}
.prodRight {
  background-image: url("./assets/ComponentTMP_0-image2.jpg");
  background-size: cover 100%;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
}
.ItRight {
  background-image: url("./assets/monitor.jpg");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  height: 100vh;
}
.ContactRight {
  background-image: url("./assets/telephone.jpg");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  height: 80vh;
}
.phone {
  width: 400px;
}
.variantCard {
  display: block;
  background-color: white;
  color: black;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.034);
  margin: 30px 10%;
}
.HSLBackground {
  background-image: url("./assets/hsl.png");
  background-size: 250px;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 400px;
}
.fwebWork {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 12px rgb(31, 31, 31);
  margin-top: 20%;
  width: 100%;
  align-self: center;
}
.fappWork {
  width: 100%;
  align-self: center;
}
@media (max-width: 1200px) {
  .serviceCard {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06) !important;
    /* margin: 8em 0em !important; */
  }
  .leftJobHead{
    background-image: url("./assets/MaskGroup20.png");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .leftHead {
    background-image: url("./assets/back.jpg");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .prodLeft {
    background-image: url("./assets/ComponentTMP_0-image2.jpg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .ItLeft {
    background-image: url("./assets/monitor.jpg");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* height: 100vh; */
  }
  .ContactLeft {
    background-image: url("./assets/telephone.jpg");
    background-size: cover;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
  }
  .AiLeft {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  .AiLeft::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 25%;
    left: 50%;
    z-index: 1;
    background: url("./assets/ComponentTMP_0-image6.jpg") no-repeat center;
    transform: rotate(-14deg) scale(2);
  }
  .OemLeft {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(203, 203, 203);
  }
  .OemLeft::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 30%;
    left: 20%;
    z-index: 0;
    background: url("./assets/proto.png") no-repeat center;
    transform: rotate(-20deg) scale(0.8);
  }
  .job-right,
  .back,
  .prodRight,
  .AiRight,
  .ItRight,
  .ContactRight,
  .OemRight {
    display: none;
  }
  
  .layer {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .rec.rec-arrow {
    display: none;
  }

  .phone {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .variantContent {
    /* min-height: 650px !important; */
  }
}
@media (max-width: 767px) {
  .blackOnSmall {
    color: black !important;
  }
  .whiteOnSmall {
    color: white !important;
  }
  .whiteSvg {
    fill: white !important;
  }
  .blackSvg {
    fill: black !important;
  }
  .VariantContainer {
    background: #131313 !important;
  }
  .variantCard {
    min-width: 200px !important;
    margin: 20% 0px !important;
  }
}

@media (max-width: 1199px) {
  .MenuButton {
    display: block !important;
  }
  .topbar-links {
    display: none;
  }
}

@media (max-width: 900px) {
  .insideLeft {
    /* padding-top: 50%;
    padding-bottom: 45%; */
  }
  /* .topbar-links {
    display: none;
  } */
  .MenuButton {
    display: block;
  }
  .AiLeft {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  .AiLeft::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -20%;
    left: 10%;
    z-index: 1;
    background: url("./assets/ComponentTMP_0-image6.jpg") no-repeat left;
    transform: rotate(-20deg);
  }
  .OemLeft {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(203, 203, 203);
  }
  .OemLeft::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40%;
    left: 20%;
    z-index: 0;
    background: url("./assets/proto.png") no-repeat center;
    transform: rotate(-20deg) scale(0.8);
  }
  .fappWork {
    max-width: 250px;
    height: auto;
  }
  .fwebWork {
    margin: 0%;
  }
}
@media (max-width: 320px) {
  .AiLeft {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  .AiLeft::before {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -20%;
    left: 20%;
    z-index: 1;
    background: url("./assets/ComponentTMP_0-image6.jpg") no-repeat left;
    transform: rotate(-20deg);
  }
  .OemLeft {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(203, 203, 203);
  }
  .OemLeft::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40%;
    left: -30%;
    z-index: 0;
    background: url("./assets/proto.png") no-repeat center;
    transform: rotate(-30deg) scale(0.5);
  }
}

.leftHeadCareer {
  height: 100vh;
  background-color: #131313;
}

@media (max-width: 1200px) {
  .leftHeadCareer {
    background-image: url("./assets/Career.jpg");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .career-right {
    display: none;
  }

  .layerCareer {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 12px;
  z-index: 1;
}
/* From https://css.glass */
/* background: rgba(255, 255, 255, 0.15);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(3.7px);
-webkit-backdrop-filter: blur(3.7px);
border: 1px solid rgba(255, 255, 255, 0.21); */
.dropdown:hover .dropdown-content {
  /* margin-top: 9px; */
  background-color: rgb(10, 10, 10, 0.5);
  border-radius: 6px;
  display: block;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.7px);
  -webkit-backdrop-filter: blur(3.7px);
  border: 1px solid rgba(255, 255, 255, 0.21);
}


.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-bottom: 5px;
}



.right-arrow {
  margin-top: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}